export const defaultState = () => ({
    WAITING: {
        value: 'waiting',
        text: 'ממתין'
    },
    CANCELLED: {
        value: 'cancelled',
        text: 'בוטל'
    },
    JOINED: {
        value: 'joined',
        text: 'רשום'
    },
    COURSE_REGISTRATION: {
        value: 'registration',
        image: 'icon_button_clock.svg'
    },
    COURSE_ACTIVE: {
        value: 'active',
        image: 'icon_button_awaiting.svg'
    },
    COURSE_ENDED: {
        value: 'ended',
        image: 'icon_button_save.svg'
    }
});

export const state = () => defaultState();

export const getters = {
    WAITING: state => state.WAITING,
    CANCELLED: state => state.CANCELLED,
    JOINED: state => state.JOINED,
    COURSE_ACTIVE: state => state.COURSE_ACTIVE,
    COURSE_REGISTRATION: state => state.COURSE_REGISTRATION,
    ALL_REGISTRATION_STATUSES: state => [
        state.WAITING,
        state.CANCELLED,
        state.JOINED
    ],
    ALL_COURSE_STATUSES: state => [
        state.COURSE_ENDED,
        state.COURSE_ACTIVE,
        state.COURSE_REGISTRATION
    ]
};

export const mutations = {};

export const actions = {
    async index(ctx, { page = 1, perPage = 7, search = '' }) {
        const params = {
            page,
            perPage,
            q: search,
            sortBy: 'createdAt',
            order: 'DESC'
        };

        return this.$axios.$get('/courses', { params });
    },

    registeredCourses() {
        const params = {
            sortBy: 'createdAt',
            order: 'DESC'
        };

        return this.$axios.$get('courses/registered', { params });
    },

    show(ctx, id) {
        return this.$axios.$get(`/courses/${id}`);
    },

    acceptInvitation(ctx, id) {
        return this.$axios.$post(`/courses/${id}/invitation`);
    },

    register(ctx, data) {
        return this.$axios.$post(`/courses/${data.id}/register`, data);
    },

    registrationDetails(ctx, id) {
        return this.$axios.$get(`/courses/${id}/details`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
